import React, { Component } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
  Table,
} from "reactstrap";
import { SERVER_URL } from "../constants/variables";
import ayekartLogo from "../assets/images/ayekartLogo.png";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { IoTrashOutline } from "react-icons/io5";

class TabsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      tabs: [],
      roles: [],
      idRoleMap: {},
      addModalOpen: false,
      tabName: "",
      dashboardNumber: "",
      accessRoles: [],
      roleTabs: [],
      superAdminId: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getTabsByRole = (roleId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      role: roleId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SERVER_URL + "/tabs/getTabsByRole", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          roleTabs: result.tabs,
        });
        console.log("Tabs: ", result.tabs);
      })
      .catch((error) => console.log("error", error));
  };

  toggleAddModal = () => {
    this.setState({
      tabName: "",
      dashboardNumber: "",
      accessRoles: [this.state.superAdminId],
      addModalOpen: !this.state.addModalOpen,
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(name, ",", value);

    this.setState({
      [name]: value,
    });
  }

  onAddTabSubmit = () => {
    var name = this.state.tabName;
    var dashboardNumber = this.state.dashboardNumber;
    var accessRoles = this.state.accessRoles;
    if (name === "" || dashboardNumber === "" || accessRoles.length === 0) {
      toast("Enter all fields");
    } else {
      toast.loading("Adding tab", {
        duration: 2000,
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: name,
        dashboardNumber: parseInt(dashboardNumber),
        roles: accessRoles,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(SERVER_URL + "/tabs/addTab", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          toast.success("Tab added successfully");
          this.getTabs();
          this.getRoles();
          this.toggleAddModal();
        })
        .catch((error) => console.log("error", error));
    }
  };

  getRoles = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(SERVER_URL + "/roles/getRoles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ roles: result.roles });
          if (result.roles) {
            var map = {};
            result.roles.forEach((item) => {
              map[item._id] = item.name;
            });
            console.log(map);
            this.setState({
              idRoleMap: map,
            });
            if (map[this.state.user.user.role] !== "SUPER_ADMIN") {
              window.location = "/login";
            }
            Object.keys(map).forEach((item) => {
              if (map[item] === "SUPER_ADMIN") {
                this.setState({
                  superAdminId: item,
                });
              }
            });
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  getTabs = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(SERVER_URL + "/tabs/getTabs", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ tabs: result.tabs });
        }
      })
      .catch((error) => console.log("error", error));
  };

  deleteRole = (id) => {
    toast.loading("", {
      duration: 2000,
    });
    var myHeaders = new Headers();
    myHeaders.append("role", id);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(SERVER_URL + "/roles/deleteRole", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        toast.success("Role deleted successfully");
        this.getTabs();
      })
      .catch((error) => console.log("error", error));
  };

  onSelectRoleRemove = (id) => {
    var accessRoles = this.state.accessRoles;
    var arr = accessRoles.filter(function (item) {
      return item !== id;
    });
    this.setState({
      accessRoles: arr,
    });
  };

  onSelectRoleChange = (e, id) => {
    var value = e.target.value;
    var accessRoles = this.state.accessRoles;
    if (accessRoles.indexOf(value) === -1) {
      accessRoles.push(value);
      this.setState({
        accessRoles: accessRoles,
      });
    }

    console.log(id);
  };

  componentDidMount() {
    if (localStorage.getItem("user") != null) {
      var user = localStorage.getItem("user");
      this.setState({
        user: JSON.parse(user),
      });
      this.getTabs();
      this.getRoles();
      this.getTabsByRole(JSON.parse(user).user.role);

      // if (user.role === "SUPER_ADMIN") {
      //   this.setState({
      //     user: user,
      //   });
      // } else {
      //   window.location = "/login";
      // }
    }
  }
  render() {
    return (
      <React.Fragment>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img
              src={ayekartLogo}
              className="img-fluid"
              alt="ayekartLogo"
              style={{ width: "120px", height: "60px" }}
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {this.state.roleTabs &&
                this.state.roleTabs.map((item) => (
                  <NavItem>
                    <Link
                      onClick={() => {
                        localStorage.setItem(
                          "dashboardNumber",
                          item.dashboardNumber
                        );
                        window.location = "/home";
                      }}
                      className="Link"
                      to="/home"
                    >
                      {item.name}
                    </Link>
                  </NavItem>
                ))}

              {this.state.user.user &&
                this.state.idRoleMap[this.state.user.user.role] ===
                  "SUPER_ADMIN" && (
                  <NavItem>
                    <Link className="Link" to="/tabs">
                      Tabs
                    </Link>
                  </NavItem>
                )}
              <NavItem>
                <Link className="Link" to="/resetPassword">
                  Reset Password
                </Link>
              </NavItem>
              <NavItem
                onClick={() => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("dashboardNumber");
                }}
              >
                <Link className="Link" to="/login">
                  Logout
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <Container fluid>
          <Row>
            <Col sm>
              <div>
                <h3>Tabs</h3>
              </div>
            </Col>
            <Col sm>
              <div style={{ textAlign: "right" }}>
                <Button color="primary" onClick={this.toggleAddModal}>
                  Add Tabs
                </Button>
              </div>
            </Col>
          </Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#ID</th>
                <th>Tab Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.tabs.map((tab) => (
                <tr>
                  <td>{tab._id}</td>
                  <td>{tab.name}</td>
                  <td onClick={() => this.deleteRole(tab._id)}>
                    <IoTrashOutline />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        {/* Add Modal */}
        <Modal isOpen={this.state.addModalOpen} toggle={this.toggleAddModal}>
          <ModalHeader toggle={this.toggleAddModal}>Add Tab</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="tabName">Tab Name</Label>
                <Input
                  type="text"
                  id="tabName"
                  name="tabName"
                  placeholder="Enter Tab Name"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dashboardNumber">Dashboard Number</Label>
                <Input
                  type="text"
                  id="dashboardNumber"
                  name="dashboardNumber"
                  placeholder="Enter Dashboard Number"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">
                  Select who can access this tab
                </Label>
                <Input
                  onChange={(e, v) => this.onSelectRoleChange(e, v)}
                  id="exampleSelect"
                  name="select"
                  type="select"
                >
                  <option value={""}></option>
                  {this.state.roles &&
                    this.state.roles.map((role) => (
                      <option value={role._id}>{role.name}</option>
                    ))}
                </Input>
              </FormGroup>
              <p>Access roles:</p>

              {this.state.accessRoles &&
                this.state.accessRoles.map((role) => (
                  <Row>
                    <Col sm>
                      <p>{this.state.idRoleMap[role]}</p>
                    </Col>
                    <Col sm>
                      <IoTrashOutline
                        onClick={() => this.onSelectRoleRemove(role)}
                      />
                    </Col>
                  </Row>
                ))}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onAddTabSubmit}>
              Add Tab
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleAddModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TabsComponent;
